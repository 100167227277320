import Vue from 'vue'
import Vuex from 'vuex'
import Vconsole from 'vconsole'
import baseUrl from './api/baseUrl'
Vue.use(Vuex)


//const LocalStorage = window.localStorage
const UserInfo = sessionStorage.getItem("userInfo")
if (UserInfo) {
	//白名单id添加开发者工具
	if (JSON.parse(UserInfo).is_debug) new Vconsole();
}
export default new Vuex.Store({
	state: {
		userInfo: UserInfo ? JSON.parse(UserInfo) : '',
		token: ""
	},
	mutations: {
		setUserInfo: (state, info) => {
			info.is_student = ['is_worker', 'is_dst', 'is_agent'].some(e => info[e]) ? 0 : 1
			sessionStorage.setItem('userInfo', JSON.stringify(info))
			state.userInfo = info
			//白名单id添加开发者工具
			if (info.is_debug) new Vconsole();
		}
	},
	actions: {

	}
})