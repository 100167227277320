<template>
    <div id="app">
        <!-- <transition :name="transitionName" mode="in-out"> -->
        <router-view v-if="isRouterAlive" />
        <!-- </transition> -->
    </div>
</template>
<script>
import wxapi from '@/common/wxApi.js';
export default {
    name: 'App',
    data() {
        return {
            isRouterAlive: true,
            keepAlive: 'main-keep-alive',  //需要缓存的页面 例如首页
            transitionName: 'slide-right', //初始过渡动画方向
        }
    },
    provide() {
        return {
            reload: this.reload,
        }
    },
    watch: {
        $route(to, from) {
            // 切换动画
            let isBack = this.$router.isBack // 监听路由变化时的状态为前进还是后退
            if (!isBack) {
                this.transitionName = 'van-slide-left'
            } else {
                this.transitionName = 'van-slide-right'
            }
            this.$nextTick(() => {
                this.$router.isBack = false
            })
        }
    },
    created() {
        // this.$api.common.uploginLog({
        //     longitude: '11',
        //     latitude: '22',
        //     city: '广州',
        //     province: "广东",
        //     platform: 1,
        //     address: "小新塘",
        // })
        //android禁止微信浏览器调整字体大小
        if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
            handleFontSize();
        } else {
            if (document.addEventListener) {
                document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
            } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", handleFontSize);
                document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
            }
        }
        function handleFontSize() {
            // 设置网页字体为默认大小
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
            // 重写设置网页字体大小的事件
            WeixinJSBridge.on('menu:setfont', function () {
                WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
            });
        }
    },
    methods: {
        reload() {
            this.isRouterAlive = false
            this.$nextTick(() => {
                this.isRouterAlive = true
            })
        },
    }
}
</script>
<style lang="less">
//@tailwind base;
// @tailwind components;
@tailwind utilities;
body {
    font-size: 14px;
    background-color: #ececec;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
}
.wth {
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    min-width: 48px !important;
    min-height: 48px !important;
    z-index: 99999 !important;
}
.container {
    padding: 14px;
}
</style>