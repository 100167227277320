import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import api from '@/api/install'
import fun from '@/common/fun'

import bootstrap from './bootstrap'

// import 'vant/lib/index.less';
//淘宝自适配rem 已使用css2rem插件 less中直接使用px做单位即可(注1:行内样式依旧使用rem单位  注2:css中不想转换的用Px单位p大写既可)
import 'amfe-flexible/index.js'
//移动端初始化样式
import '@/style/reset.css'
//iview组件库样式
import '@/style/iview.css'
// 引入重置vant的样式
import '@/style/vantRest.less'
//全局公用样式
import '@/style/common.less'

// 注册经常使用的组件
Vue.use(api)
  .use(bootstrap)

// 若是没有开启Devtools工具，在开发环境中开启，在生产环境中关闭
if (process.env.NODE_ENV == 'development') {
  Vue.config.devtools = true;
} else {
  Vue.config.devtools = false;
}

//全局过滤器
Vue.filter('roleName', function (value) {
  if (!value) return '错误'
  switch (value) {
    case 'dst':
      value = '教练端'
      break;
    case 'worker':
      value = '装机端'
      break;
    case 'student':
      value = '学员端'
      break;
    case 'agent':
      value = '区代端'
      break;
    default: value = '教练端'
      break;
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
})
//todo  开发环境下存虚假的信息方便替换和调试
if (process.env.NODE_ENV === "development") {
  if (!sessionStorage.getItem('userInfo')) sessionStorage.setItem('userInfo', '{"id":81,"uid":57954,"mobile":"17319110093","type":1,"nickname":"吕小布","realname":"","head_portrait":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLx7wqseNibeE9lFIzfhxpSNeKdg6PHqrDWHpxdiay8eU99Wnfbtc3bEFC3OsYziaRic0lUicrjQQI7qiag/132","gender":1,"status":1,"is_agent":1,"is_dst":1,"is_worker":1,"is_debug":1,"device":[],"is_student":0}')
  let auth = sessionStorage.getItem('auth')
  if (!auth) {
    const access_token = prompt("检测到当前为开发环境 请先输入 access_token");
    sessionStorage.setItem('auth', JSON.stringify({
      access_token,
      expires_in: ~~(Date.now() / 1000) + 60 * 60 * 24 
    }))
  }
}
Vue.config.productionTip = false
Vue.prototype.$fun = fun
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
